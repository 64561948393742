import React, { useMemo } from 'react';
import { Activity, EkoWork, Process, User } from 'types';
import Report from '../../../models/report';
import { styled } from 'styled-components';
import moment from 'moment';
import { formatDate, formatDateTime } from 'utils';
import { logo } from 'assets/images';
import { Filters } from '../../../views/eko_works/components/filters';
import { Photos } from './photos';
import { Summary } from './summary';

const Wrapper = styled.div<{ orientation: 'portrait' | 'landscape' }>`
	display: none;
	flex-direction: column;

	@media print {
		display: flex;
		transform: none;

		@page {
			margin: 16px;
			size: ${(props) => props.orientation};
		}
	}

	table {
		width: 100%;
		font-size: 12px;
		margin-bottom: 56px;

		> tbody,
		thead {
			> tr {
				break-inside: avoid;

				&.row-with-photos {
					break-after: avoid;
				}

				&.photos-row {
					break-before: avoid;
				}

				&.odd {
					background: rgba(0, 0, 0, 0.1);
				}

				> th {
					text-align: center;
					font-weight: bold;
					color: #000;

					padding: 0 4px;
					border: 1px solid black;
				}

				> td {
					color: #000;
					text-align: center;

					padding: 4px 4px;
					border: 1px solid black;
				}
			}
		}
	}
`;

const Header = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;

	margin-bottom: 56px;

	> img {
		width: 300px;
		object-fit: contain;
		align-self: flex-end;
	}

	> h1 {
		margin: 0 56px;
		color: rgba(0, 0, 0, 0.8);
		font-size: 32px;
		font-weight: bold;
		align-self: center;
	}

	> table {
		align-self: center;

		tr {
			font-size: 16px;

			> td {
				padding: 2px 4px;
				border: 1px solid black;

				&:nth-child(2n + 1) {
					color: rgba(0, 0, 0, 0.5);
					padding-right: 24px;
				}

				&:nth-child(2n) {
					color: #000;
					font-weight: bold;
				}
			}
		}
	}
`;

export type ColumnType =
	| 'index'
	| 'workers'
	| 'process'
	| 'date'
	| 'field'
	| 'activity'
	| 'amount'
	| 'unit'
	| 'amountAndUnit'
	| 'comments'
	| 'report'
	| 'added'
	| 'added_by';
export type PossibleColumn = { label: string; key: ColumnType };
export const possibleColumns: PossibleColumn[] = [
	{
		label: 'LP.',
		key: 'index',
	},
	{
		label: 'Pracownicy',
		key: 'workers',
	},
	{
		label: 'Sprawa',
		key: 'process',
	},
	{
		label: 'Data',
		key: 'date',
	},
	{
		label: 'Nr pola',
		key: 'field',
	},
	{
		label: 'Czynność',
		key: 'activity',
	},
	{
		label: 'Ilość',
		key: 'amount',
	},
	{
		label: 'Jednostka',
		key: 'unit',
	},
	{
		label: 'Ilość + jednostka',
		key: 'amountAndUnit',
	},
	{
		label: 'Komentarze',
		key: 'comments',
	},
	{
		label: 'Numer raportu',
		key: 'report',
	},
	{
		label: 'Data dodania',
		key: 'added',
	},
	{
		label: 'Dodane przez',
		key: 'added_by',
	},
];

interface Props {
	filters: Filters;
	selectedColumns: PossibleColumn[];
	printHeader: boolean;
	printLogo: boolean;
	printPhotos: boolean;
	orientation: 'portrait' | 'landscape';
	processes: Process[] | undefined;
	activities: Activity[] | undefined;
	users: User[] | undefined;
	reports: Report[] | undefined;
	ekoWorks: EkoWork[] | undefined;
}

type Data = Record<ColumnType, string> & { id: number };

type Row = Record<ColumnType, string | undefined> & { id: number };

interface HeaderData {
	process?: string | undefined;
	selectedActivities?: string | undefined;
	period?: string | undefined;
	selectedWorkers?: string | undefined;
	selectedFields?: string | undefined;
	commentsSearch?: string | undefined;
	withoutReport?: boolean | undefined;
	totalRows?: number | undefined;
}

const _Print: React.FC<Props> = ({
	filters,
	selectedColumns,
	printHeader,
	printLogo,
	printPhotos,
	orientation,
	ekoWorks,
	users,
	processes,
	activities,
	reports,
}) => {
	const usersMap = useMemo(() => {
		if (!users) {
			return undefined;
		}

		const entries = users.map<[number, User]>((user) => [user.id, user]);
		return Object.fromEntries(entries);
	}, [JSON.stringify(users)]);

	const processesMap = useMemo(() => {
		if (!processes) {
			return undefined;
		}

		const entries = processes.map<[string, Process]>((process) => [process.prc_id, process]);
		return Object.fromEntries(entries);
	}, [JSON.stringify(processes)]);

	const activitiesMap = useMemo(() => {
		if (!activities) {
			return undefined;
		}

		const entries = activities.map<[number, Activity]>((activity) => [activity.id, activity]);
		return Object.fromEntries(entries);
	}, [JSON.stringify(activities)]);

	const reportsMap = useMemo(() => {
		if (!reports) {
			return undefined;
		}

		const entries = reports.map<[number, Report]>((report) => [report.id, report]);
		return Object.fromEntries(entries);
	}, [JSON.stringify(reports)]);

	if (!ekoWorks || !usersMap || !processesMap || !activitiesMap || !reportsMap) {
		return <p>Ładowanie danych</p>;
	}

	const data = ekoWorks.map<Data>((work, index) => {
		const activity = activitiesMap[work.activityId];
		const amount = work.amount;
		const unit = activity?.unit || '';
		const addedBy = usersMap[work.addedBy];
		const report = work.reportId ? reportsMap[work.reportId] : undefined;

		return {
			id: work.id,
			index: (index + 1).toString(),
			workers: work.users
				.map((user) => usersMap[user.userId])
				.map((user) => (user ? user.surname + ' ' + user.name : '?'))
				.join(', '),
			process: processesMap[work.processId]?.dscrpt ?? '-',
			date: formatDate(moment(work.date)),
			field: work.field,
			activity: activity?.name || '-',
			amount: amount.toString(),
			unit,
			amountAndUnit: `${amount} ${unit}`,
			comments: work.comments ?? '-',
			report: report ? report.number.toString() : '-',
			added: formatDateTime(moment(work.added)),
			added_by: addedBy ? addedBy?.surname + ' ' + addedBy?.name : '?',
		};
	});

	const rows = data.map<Row>((data) => {
		const row: Row = {
			id: data.id,
		} as Row;

		for (const column of selectedColumns) {
			row[column.key] = data[column.key];
		}

		return row;
	});

	const headerData = (() => {
		const data: HeaderData = {};

		if (filters.process) {
			data.process = processesMap[filters.process.prc_id]?.dscrpt;
		}

		if (filters.selectedActivities) {
			data.selectedActivities = filters.selectedActivities
				.map((activity) => activitiesMap[activity.id]?.name ?? '?')
				.join(', ');
		}

		if (filters.after && filters.before) {
			data.period = `${formatDate(filters.after)} - ${formatDate(filters.before)}`;
		} else if (filters.after) {
			data.period = `od ${formatDate(filters.after)}`;
		} else if (filters.before) {
			data.period = `do ${formatDate(filters.before)}`;
		}

		if (filters.selectedWorkers) {
			data.selectedWorkers = filters.selectedWorkers
				.map((user) => {
					const worker = usersMap[user.id];
					if (!worker) {
						return '?';
					}

					return worker.surname + ' ' + worker.name;
				})
				.join(', ');
		}

		if (filters.selectedFields) {
			data.selectedFields = Array.from(filters.selectedFields).join(', ');
		}

		if (filters.commentsSearch) {
			data.commentsSearch = filters.commentsSearch;
		}

		if (filters.withoutReport) {
			data.withoutReport = true;
		}

		data.totalRows = rows.length;

		return data;
	})();

	return (
		<Wrapper className="prints" orientation={orientation}>
			<Header>
				{printLogo && <img src={logo} alt="logo" />}
				{printHeader && (
					<React.Fragment>
						<h1>RAPORT PRAC</h1>
						<table>
							<tbody>
								<tr>
									<td>Data sporządzenia:</td>
									<td>{formatDateTime(moment())}</td>
								</tr>
								<tr>
									<td>Okres prac:</td>
									<td>{headerData.period ?? 'całość'}</td>
								</tr>
								<tr>
									<td>Dla sprawy:</td>
									<td>{headerData.process ?? '-'}</td>
								</tr>
								<tr>
									<td>Dla czynności:</td>
									<td>{headerData.selectedActivities ?? 'wszystkie'}</td>
								</tr>
								<tr>
									<td>Dla wybranych pól:</td>
									<td>{headerData.selectedFields ?? 'wszystkie'}</td>
								</tr>
								<tr>
									<td>Komentarz zawiera:</td>
									<td>{headerData.commentsSearch ?? '-'}</td>
								</tr>
								<tr>
									<td>Tylko bez raportu:</td>
									<td>{headerData.withoutReport ? 'tak' : 'nie'}</td>
								</tr>
								<tr>
									<td>Wszystkich wpisów:</td>
									<td>{headerData.totalRows ?? '?'}</td>
								</tr>
							</tbody>
						</table>
					</React.Fragment>
				)}
			</Header>
			<table>
				<thead>
					<tr>
						{selectedColumns.map((column) => (
							<th key={column.key}>{column.label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => {
						const { id, ...columns } = row;
						const columnEntries = Object.entries(columns);

						const className = index % 2 ? 'even' : 'odd';
						const trContent = columnEntries.map(([key, column]) => <td key={key}>{column}</td>);
						const tr = (
							<tr key={row['id']} className={className}>
								{trContent}
							</tr>
						);

						if (!printPhotos) {
							return tr;
						}

						const ekoWork = ekoWorks.find((ekoWork) => ekoWork.id === id);
						if (!ekoWork || ekoWork.photos.length === 0) {
							return tr;
						}

						return (
							<React.Fragment key={id}>
								<tr className={`row-with-photos ${className}`}>{trContent}</tr>
								<tr className={`photos-row ${className}`}>
									<td colSpan={selectedColumns.length}>
										<Photos ekoWorks={[ekoWork]} orientation={orientation} />
									</td>
								</tr>
							</React.Fragment>
						);
					})}
				</tbody>
			</table>
			<Summary ekoWorks={ekoWorks} activities={activities} />
		</Wrapper>
	);
};

export class Print extends React.PureComponent<Props> {
	override render() {
		return <_Print {...this.props} />;
	}
}
