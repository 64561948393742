import { Activity, EkoWork } from 'types';

interface EkoWorksGroupedByActivity {
	[key: number]: GroupedWorks;
}

interface GroupedWorks {
	activity: Activity;
	works: EkoWork[];
}

const groupEkoWorksByActivity = (works: EkoWork[], activities: Activity[]): EkoWorksGroupedByActivity => {
	const result: EkoWorksGroupedByActivity = {};
	for (const work of works) {
		const existing = result[work.activityId];
		if (existing) {
			existing.works.push(work);
			continue;
		}

		const activity = activities.find((activity) => activity.id === work.activityId);
		if (!activity) {
			continue;
		}

		result[work.activityId] = { activity: activity, works: [work] };
	}

	return result;
};

export { groupEkoWorksByActivity as default, EkoWorksGroupedByActivity, GroupedWorks };
