export interface CancelablePromise<T> {
	promise: Promise<T>;
	cancel: () => void;
}

export function cancelablePromise<T>(promise: Promise<T>): CancelablePromise<T> {
	let isCancelled = false;

	const wrapper = async (): Promise<T> => {
		try {
			const result = await promise;
			if (isCancelled) {
				throw true;
			}

			return result;
		} catch (error) {
			if (isCancelled) {
				throw true;
			}

			throw error;
		}
	};

	return {
		promise: wrapper(),
		cancel: () => (isCancelled = true),
	};
}
