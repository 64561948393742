import moment from 'moment';

class Report {
	constructor(
		id: number,
		processId: number,
		after: moment.Moment,
		before: moment.Moment,
		generated: moment.Moment,
		number: number,
		scope: string,
		mocked: boolean,
		notices: string,
		createdBy: number
	) {
		this.id = id;
		this.processId = processId;
		this.after = after;
		this.before = before;
		this.generated = generated;
		this.number = number;
		this.scope = scope;
		this.mocked = mocked;
		this.notices = notices;
		this.createdBy = createdBy;
	}

	readonly id: number;
	readonly processId: number;
	readonly after: moment.Moment;
	readonly before: moment.Moment;
	readonly generated: moment.Moment;
	readonly number: number;
	readonly scope: string;
	readonly mocked: boolean;
	readonly notices: string;
	readonly createdBy: number;

	static fromJson(json: { [key: string]: any }): Report {
		return new Report(
			json['id']!,
			json['processId']!,
			moment(new Date(json['after']!)),
			moment(new Date(json['before']!)),
			moment(new Date(json['generated']!)),
			json['number']!,
			json['scope']!,
			json['mocked']!,
			json['notices']!,
			json['createdBy']
		);
	}

	isForDate(date: moment.Moment): boolean {
		return date.isAfter(this.after) && date.isBefore(this.before);
	}
}

export default Report;
