import qs, { IParseOptions, IStringifyOptions } from 'qs';

export const q = {
	stringify: (params: any, options?: IStringifyOptions) => {
		return qs.stringify(params, {
			addQueryPrefix: false,
			arrayFormat: 'repeat',
			...options,
		});
	},
	parse: (search: string, options?: IParseOptions) => {
		return qs.parse(search, {
			ignoreQueryPrefix: true,
			...options,
		});
	},
};
