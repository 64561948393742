import React from 'react';
import { styled } from 'styled-components';
import { EkoWork } from 'types';
import API from '../../../API';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 16px;

	> .row {
		display: flex;
		flex-direction: row;
		column-gap: 40px;
		padding: 0 40px;

		> * {
			flex: 1;
			aspect-ratio: 1;
			object-fit: cover;
			break-inside: avoid;
			// Makes the image actually listen to the flex.
			width: 100px;
		}
	}
`;

interface Props {
	orientation: 'portrait' | 'landscape';
	ekoWorks: EkoWork[];
}

export const Photos: React.FC<Props> = ({ orientation, ekoWorks }) => {
	const photos = ekoWorks.flatMap((ekoWork) => {
		return ekoWork.photos.map(({ photo_name }) => {
			const src = API.getAssetUrl(`small_${photo_name}`);

			return <img key={photo_name} src={src} alt={photo_name} />;
		});
	});

	const partitioned: React.JSX.Element[][] = [];
	const rowSize = orientation === 'portrait' ? 2 : 3;

	for (let i = 0; i < photos.length; i += rowSize) {
		const slice = photos.slice(i, i + rowSize);
		while (slice.length < rowSize) {
			slice.push(<div key={`empty-${i + slice.length}`} />);
		}

		partitioned.push(slice);
	}

	const images = partitioned.map((photoNames, i) => {
		return (
			<div className="row" key={i}>
				{photoNames}
			</div>
		);
	});

	return <Wrapper className={orientation}>{images}</Wrapper>;
};
