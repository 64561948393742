import { UserLevel } from './userLevel';
import { User as UserI } from '../types';

class User implements UserI {
	constructor(
		id: number,
		ext_id: number | null,
		login: string | null,
		name: string,
		surname: string,
		company: string | null,
		phone: string | null,
		email: string | null,
		type: string | null,
		level: string,
		token: string | null,
		rodo: string,
		newsletter: string | null
	) {
		this.id = id;
		this.ext_id = ext_id;
		this.login = login;
		this.name = name;
		this.surname = surname;
		this.company = company;
		this.phone = phone;
		this.email = email;
		this.type = type;
		this.level = level;
		this.token = token;
		this.rodo = rodo;
		this.newsletter = newsletter;
	}

	readonly id: number;
	readonly ext_id: number | null;
	readonly login: string | null;
	readonly name: string;
	readonly surname: string;
	readonly company: string | null;
	readonly phone: string | null;
	readonly email: string | null;
	readonly type: string | null;
	readonly level: string;
	readonly token: string | null;
	readonly rodo: string;
	readonly newsletter: string | null;

	static fromJson(json: { [key: string]: any }): User {
		return new User(
			json['id']!,
			json['ext_id']!,
			json['login']!,
			json['name'],
			json['surname']!,
			json['company']!,
			json['phone']!,
			json['email']!,
			json['type']!,
			json['level']!,
			json['token']!,
			json['rodo']!,
			json['newsletter']!
		);
	}

	get displayName(): string {
		return `${this.surname} ${this.name}`;
	}

	get isEmployee(): boolean {
		if (this.level === UserLevel.employee) {
			return true;
		}

		if (this.level === UserLevel.employee_with_works) {
			return true;
		}

		if (this.level === UserLevel.employee_inactive) {
			return true;
		}

		return false;
	}

	get hasWorks(): boolean {
		return this.level === UserLevel.employee_with_works;
	}
}

export default User;
