import React, { useEffect } from 'react';

import './panel_loading.scss';

interface Props {
	onLoaded: () => Promise<void>;
}

const PanelLoading: React.FC<Props> = ({ onLoaded }: Props) => {
	useEffect(() => {
		onLoaded();
	}, []);

	return (
		<div className="panel-loading">
			<p>Ładowanie...</p>
		</div>
	);
};

export default PanelLoading;
