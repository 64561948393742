import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Query } from '../utils';
import base64url from 'base64url';

import '../styles/pages/login.scss';

const Login = ({ onLogin }: { onLogin: () => void }) => {
	const history = useHistory();
	const [login, setLogin] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>();

	const query = Query.decode(history.location.search);
	const loggedOut = query['logout'] || history.location.pathname === '/logout';
	const logout = loggedOut && 'Wylogowano';

	const redirectToOauth = () => {
		const splitLocation = window.location.href.split('/');
		const host = splitLocation.slice(0, 3).join('/');
		const base64Location = base64url(host);

		window.location.href = `https://firma.ekoposadzki.pl/mobile_api.php?ret=${base64Location}&API_KEY=7c44b65f2647081e3c174ffe65b4f77c`;
	};

	const getApi = (): Promise<{
		login: (login: string, pass: string) => Promise<any>;
		managerLogin: (token: string, refreshToken: string) => Promise<any>;
	}> =>
		new Promise((resolve, reject) => {
			import('../API')
				.then((API) => {
					const { login, managerLogin } = API.default;

					resolve({ login, managerLogin });
				})
				.catch(reject);
		});

	const loginAsUser = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!login.trim()) return setError('Podaj login');
		if (!password.trim()) return setError('Podaj hasło');

		try {
			setLoading(true);

			const { login: loginMethod } = await getApi();

			const result = await loginMethod(login, password);
			sessionStorage.setItem('jwt', result.jwt);
			sessionStorage.setItem('id', result.id);
			sessionStorage.setItem('level', result.level);

			await onLogin();

			setLoading(false);

			history.replace('/panel');
		} catch (e) {
			setLoading(false);
			console.trace(e);

			setError('Niepoprawne dane logowania');
		}
	};

	const loginAsManager = async (token: string, refreshToken: string) => {
		try {
			setLoading(true);

			const { managerLogin } = await getApi();

			const result = await managerLogin(token, refreshToken);
			sessionStorage.setItem('id', result.id);
			sessionStorage.setItem('level', result.level);

			await onLogin();

			setLoading(false);
			history.replace('/panel/hours');
		} catch (e) {
			setLoading(false);

			setError(`${e}`);
		}
	};

	useEffect(() => {
		const { token, refreshToken } = query;

		if (typeof token == 'string' && typeof refreshToken == 'string') {
			loginAsManager(token, refreshToken);
		}
	}, []);

	return (
		<div id="login" className="flex flex-dir flex-jc flex-ac">
			<form onSubmit={loginAsUser}>
				<div className="flex flex-dir flex-jc flex-ac">
					<h1>Zaloguj się</h1>
					<div>
						<p className={error ? 'error' : undefined}>{error ?? logout}</p>
					</div>

					<Input
						size="large"
						placeholder="Login"
						prefix={<UserOutlined />}
						value={login}
						onChange={(e) => setLogin(e.target.value)}
					/>

					<Input
						size="large"
						type="password"
						placeholder="Hasło"
						prefix={<LockOutlined />}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<input type="submit" />

					<Button size="large" type="primary" loading={loading} onClick={loginAsUser}>
						Zaloguj
					</Button>
					<p onClick={redirectToOauth}>Zaloguj się jako kierownik</p>
				</div>
			</form>
			<span>v3.10.2</span>
		</div>
	);
};

export default Login;
