import React, { useMemo } from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface Props<T, IdType> {
	width?: string | undefined;
	placeholder?: string;
	allowClear?: boolean;
	elements: T[];
	value: IdType[];
	id: string | undefined;
	onChange: (id: IdType[]) => void;
	buildElement: (element: T) => any;
}

export function MultipleSearchSelector<T, IdType>({
	placeholder,
	elements,
	allowClear,
	value,
	id,
	onChange,
	buildElement,
	width,
}: Props<T, IdType>) {
	const options = useMemo(() => {
		return elements?.map((element: T) => {
			const key = id ? element[id] : element;

			return (
				<Option key={key} value={key}>
					{buildElement(element)}
				</Option>
			);
		});
	}, [elements]);

	return (
		<Select
			showSearch
			allowClear={allowClear ?? false}
			mode="multiple"
			placeholder={placeholder}
			style={{ width: width ?? 'auto' }}
			value={value}
			onChange={(id) => onChange(id)}
			optionFilterProp="children"
			filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
		>
			{options}
		</Select>
	);
}
