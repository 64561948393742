export const UserLevel = {
	inactive: 'inactive',
	user_low: 'user_low',
	user_high: 'user_high',
	employee: 'employee',
	employee_with_works: 'employee_with_works',
	employee_inactive: 'employee_inactive',
	manager: 'manager',
	admin: 'admin',
};

export const getAccountType = (level: string) => {
	switch (level) {
		case UserLevel.inactive:
			return 'Nieaktywowany';

		case UserLevel.user_low:
			return 'Kategoria 1';

		case UserLevel.user_high:
			return 'Kategoria 2';

		case UserLevel.admin:
			return 'Administrator';
	}

	return 'Nieznany';
};
