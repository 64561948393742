import API from '../../../API';
import { message } from 'antd';
import { UserLevel } from 'models/userLevel';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Activity, EkoWork, Process, User } from 'types';
import { q } from 'utils';
import { useFilters } from 'views/eko_works/use_filters';
import Report from '../../../models/report';
import { PossibleColumn, possibleColumns } from 'pages/prints/eko_works/print';

export const useEkoWorksPrint = () => {
	const history = useHistory();
	const query = q.parse(history.location.search);
	const currentLevel = sessionStorage.getItem('level');

	const [processes, setProcesses] = useState<Process[]>();
	const [activities, setActivities] = useState<Activity[]>();
	const [users, setUsers] = useState<User[]>();
	const [reports, setReports] = useState<Report[]>();
	const [ekoWorks, setEkoWorks] = useState<EkoWork[]>();

	const filters = useFilters({
		processes,
		activities,
		workers: users,
	});

	const { process, selectedActivities, after, before, selectedWorkers } = filters;

	useEffect(() => {
		fetchProcesses();
		fetchActivities();
		fetchUsers();
	}, []);

	useEffect(() => {
		if (!process) {
			setEkoWorks(undefined);
			setReports(undefined);
			return;
		}

		fetchEkoWorks(process.prc_id, selectedActivities, after, before, selectedWorkers);
		fetchReports(process.prc_id);
	}, [
		process,
		JSON.stringify(selectedActivities?.map((activity) => activity.id)),
		after?.unix(),
		before?.unix(),
		JSON.stringify(selectedWorkers?.map((worker) => worker.id)),
	]);

	const filteredEkoWorks = useMemo(() => {
		if (!ekoWorks) {
			return undefined;
		}

		return ekoWorks.filter((ekoWork) => {
			if (filters.selectedFields) {
				if (!filters.selectedFields.has(ekoWork.field)) {
					return false;
				}
			}

			if (filters.commentsSearch) {
				if (!ekoWork.comments?.toLocaleLowerCase().includes(filters.commentsSearch.toLocaleLowerCase())) {
					return false;
				}
			}

			if (filters.withoutReport) {
				if (ekoWork.reportId) {
					return false;
				}
			}

			return true;
		});
	}, [ekoWorks, filters.selectedFields, filters.commentsSearch, filters.withoutReport]);

	const selectedColumnsRaw = query['selected_columns'];
	const printLogo = query['print_logo'];
	const printHeader = query['print_header'];
	const printPhotos = query['print_photos'];
	const orientation = query['orientation'];

	const params = useMemo(() => {
		if (!selectedColumnsRaw) {
			return undefined;
		}

		if (orientation !== 'portrait' && orientation !== 'landscape') {
			return undefined;
		}

		const selectedColumns = Array.isArray(selectedColumnsRaw) ? selectedColumnsRaw : [selectedColumnsRaw];

		return {
			selectedColumns: selectedColumns
				.map((key: string) => possibleColumns.find((column) => column.key === key))
				.filter((column) => column) as PossibleColumn[],
			printLogo: !!(printLogo && printLogo !== 'false'),
			printHeader: !!(printHeader && printHeader !== 'false'),
			printPhotos: !!(printPhotos && printPhotos !== 'false'),
			orientation: orientation as 'portrait' | 'landscape',
		};
	}, [selectedColumnsRaw, printLogo, printHeader, printPhotos, orientation]);

	const fetchProcesses = async () => {
		try {
			const processes = await API.processes.getAll();

			setProcesses(processes);
		} catch (e) {
			console.trace(e);

			message.error('Nie można pobrać danych spraw');
		}
	};

	const fetchActivities = async () => {
		try {
			const activities = await API.activities.getAll();

			setActivities(activities);
		} catch (e) {
			console.trace(e);

			message.error('Nie można pobrać danych czynności');
		}
	};

	const fetchUsers = async () => {
		try {
			const users = await API.users.getAll();

			setUsers(users);
		} catch (e) {
			console.trace(e);

			message.error('Nie można pobrać danych użytkowników');
		}
	};

	const fetchEkoWorks = async (
		processId: string,
		selectedActivities: Activity[] | undefined,
		after: moment.Moment | undefined,
		before: moment.Moment | undefined,
		selectedWorkers: User[] | undefined
	) => {
		try {
			const result = await API.ekoWorks.getAll({
				processId,
				activityIds: selectedActivities?.map((activity) => activity.id),
				after,
				before,
				userIds: selectedWorkers?.map((worker) => worker.id),
			});

			setEkoWorks(result);
		} catch (e) {
			console.trace(e);

			message.error('Nie można pobrać danych');
		}
	};

	const fetchReports = async (processId: string) => {
		if (currentLevel !== UserLevel.admin) {
			setReports([]);

			return;
		}

		try {
			const reports = await API.reports.getAll({ process_id: processId });

			setReports(reports);
		} catch (e) {
			console.trace(e);

			message.error('Nie można pobrać danych');
		}
	};

	const isReady = process && activities && users && reports && filteredEkoWorks && params;
	useEffect(() => {
		if (!isReady) {
			return;
		}

		const timeout = setTimeout(() => {
			console.log('{{ready}}');
		}, 200);

		return () => {
			clearTimeout(timeout);
		};
	}, [isReady]);

	return {
		filters,
		processes,
		activities,
		users,
		reports,
		ekoWorks: filteredEkoWorks,
		params,
	};
};
