export type QueryObject = { [key: string]: string | Array<string> | undefined };

export default {
	decode: (query: string, delimiter: string = '&') => {
		if (!query) return {};

		if (query.startsWith('?')) {
			query = query.substring(1);
		}

		return query.split(delimiter).reduce((acc: QueryObject, e: string) => {
			const [k, v] = e.split('=');

			if (!acc[k]) {
				return { ...acc, [k]: v };
			}

			if (typeof acc[k] === 'string') {
				return { ...acc, [k]: [acc[k] as string, v] };
			}

			(acc[k] as Array<string>).push(v);
			return acc;
		}, {});
	},

	encode: (data: QueryObject, delimiter: string = '&') => {
		if (!data) return '';

		const components: Array<string> = [];

		for (const [k, v] of Object.entries(data)) {
			if (!k || !v) continue;
			if (typeof v === 'string' || typeof v === 'number') {
				components.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
			} else {
				for (const value of v) {
					components.push(`${encodeURIComponent(k)}=${encodeURIComponent(value)}`);
				}
			}
		}
		return components.join(delimiter);
	},
};
