import { Button, Checkbox, DatePicker, Input, Skeleton } from 'antd';
import React, { useMemo } from 'react';
import moment from 'moment';
import SearchSelector from 'components/searchSelector';
import { Activity, Process, User } from 'types';
import styled from 'styled-components';
import { MultipleSearchSelector } from 'components/multipleSearchSelector';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 24px;

	> div {
		width: 100%;
		display: flex;
		align-items: center;
		column-gap: 24px;
		row-gap: 24px;
		flex-wrap: wrap;
		overflow-x: auto;

		> .process-selector {
			min-width: 450px;
			flex: 5;
		}

		> .activity-selector {
			min-width: 450px;
			flex: 5;
		}

		> .date-range-picker {
			min-width: 300px;
			flex: 3;
		}

		> .workers-selector {
			min-width: 400px;
			flex: 5;
		}

		> .fields-selector {
			min-width: 350px;
			flex: 3;
		}

		> .flex-row {
			display: flex;
			flex-direction: row;
			align-items: center;
			column-gap: 24px;
			row-gap: 24px;
			flex: 8;

			> .comments-seeker {
				min-width: 350px;
				flex: 3;
			}

			> .without-report-checkbox {
				white-space: nowrap;
				display: flex;
				flex-direction: row;
				align-items: center;

				> span {
					top: 0;
				}
			}
		}
	}
`;

export interface Filters {
	process?: Process | undefined;
	selectedActivities?: Activity[] | undefined;
	after?: moment.Moment | undefined;
	before?: moment.Moment | undefined;
	selectedWorkers?: User[] | undefined;
	selectedFields?: Set<string> | undefined;
	commentsSearch?: string | undefined;
	withoutReport?: boolean | undefined;
}

export const filtersToQuery = (filters: Filters): Record<string, string | string[]> => {
	const newQuery: Record<string, string | string[]> = {};

	if (filters.process) {
		newQuery['process_id'] = filters.process.prc_id.toString();
	}

	if (filters.selectedActivities) {
		newQuery['activity_id'] = filters.selectedActivities.map((activity) => activity.id.toString());
	}

	if (filters.after) {
		newQuery['after'] = filters.after.toISOString();
	}

	if (filters.before) {
		newQuery['before'] = filters.before.toISOString();
	}

	if (filters.selectedWorkers) {
		newQuery['worker_id'] = filters.selectedWorkers.map((worker) => worker.id.toString());
	}

	if (filters.selectedFields) {
		newQuery['field'] = Array.from(filters.selectedFields);
	}

	if (filters.commentsSearch) {
		newQuery['comments_search'] = filters.commentsSearch;
	}

	if (filters.withoutReport) {
		newQuery['without_report'] = 'true';
	}

	return newQuery;
};

interface Props {
	processes: Process[] | undefined;
	activities: Activity[] | undefined;
	workers: User[] | undefined;
	fields: string[] | undefined;
	filters: Filters;
	onChange: (filters: Filters) => void;
}

export const Filters: React.FC<Props> = ({ processes, activities, workers, fields, filters, onChange }) => {
	const filteredActivities = useMemo(() => {
		const { process } = filters;
		if (!activities || !process) {
			return undefined;
		}

		return activities.filter((activity) => activity.processId === parseInt(process.prc_id));
	}, [filters.process, activities]);

	if (!processes || !filteredActivities || !workers || !fields) {
		return <Skeleton active />;
	}

	const onFiltersChange = (wanted: {
		processId?: string;
		activityIds?: number[] | null;
		after?: moment.Moment | null;
		before?: moment.Moment | null;
		workerIds?: number[] | null;
		fields?: Set<string> | null;
		commentsSearch?: string;
		withoutReport?: boolean;
	}) => {
		const newFilters: Filters = {};

		const wantedProcessId = wanted.processId ?? filters.process?.prc_id;
		if (wantedProcessId) {
			newFilters.process = processes.find((process) => process.prc_id === wantedProcessId);
		}

		if (wanted.activityIds !== null) {
			const wantedActivityId = wanted.activityIds ?? filters.selectedActivities?.map((activity) => activity.id);
			if (wantedActivityId) {
				const selected = new Set(wantedActivityId);

				newFilters.selectedActivities = filteredActivities.filter((activity) => selected.has(activity.id));
			}
		}

		if (wanted.after !== null) {
			const wantedAfter = wanted.after ?? filters.after;
			if (wantedAfter) {
				newFilters.after = wantedAfter;
			}
		}

		if (wanted.before !== null) {
			const wantedBefore = wanted.before ?? filters.before;
			if (wantedBefore) {
				newFilters.before = wantedBefore;
			}
		}

		if (wanted.workerIds !== null) {
			const wantedSelectedWorkers = wanted.workerIds ?? filters.selectedWorkers?.map((worker) => worker.id);
			if (wantedSelectedWorkers) {
				const selected = new Set(wantedSelectedWorkers);

				newFilters.selectedWorkers = workers.filter((worker) => selected.has(worker.id));
			}
		}

		if (wanted.fields !== null) {
			const wantedSelectedFields = wanted.fields ?? filters.selectedFields;
			if (wantedSelectedFields) {
				newFilters.selectedFields = wantedSelectedFields;
			}
		}

		newFilters.commentsSearch = wanted.commentsSearch ?? filters.commentsSearch;
		newFilters.withoutReport = wanted.withoutReport ?? filters.withoutReport;

		onChange(newFilters);
	};

	const clearFilters = () => {
		onChange({
			process: filters.process,
		});
	};

	const dateRangeValue: [moment.Moment, moment.Moment] | null = (() => {
		if (!filters.after) {
			return null;
		}

		if (!filters.before) {
			return null;
		}

		return [filters.after, filters.before];
	})();

	return (
		<Wrapper>
			<div>
				<div className="process-selector">
					<SearchSelector
						width="100%"
						elements={processes}
						value={filters.process?.prc_id}
						id="prc_id"
						onChange={(processId) => processId && onFiltersChange({ processId })}
						buildElement={(process) => process.dscrpt}
					/>
				</div>
				<div className="activity-selector">
					<MultipleSearchSelector
						width="100%"
						allowClear
						placeholder="Filtruj po czynnościach"
						elements={filteredActivities}
						value={filters.selectedActivities?.map((activity) => activity.id) ?? []}
						id="id"
						onChange={(activityIds) => onFiltersChange({ activityIds })}
						buildElement={(activity) => activity.name}
					/>
				</div>
				<DatePicker.RangePicker
					className="date-range-picker"
					value={dateRangeValue}
					onChange={(dates) =>
						onFiltersChange({
							after: dates?.[0]?.clone().startOf('day') ?? null,
							before: dates?.[1]?.clone().endOf('day') ?? null,
						})
					}
				/>
				<div className="workers-selector">
					<MultipleSearchSelector
						width="100%"
						placeholder="Filtruj po pracownikach"
						allowClear
						elements={workers}
						value={filters.selectedWorkers?.map((worker) => worker.id) ?? []}
						id="id"
						onChange={(workerIds) => onFiltersChange({ workerIds: workerIds.length ? workerIds : null })}
						buildElement={(worker) => worker.surname + ' ' + worker.name}
					/>
				</div>
				<div className="fields-selector">
					<MultipleSearchSelector
						width="100%"
						placeholder="Filtruj po polach"
						allowClear
						elements={fields}
						value={Array.from(filters.selectedFields ?? [])}
						id={undefined}
						onChange={(fields) => onFiltersChange({ fields: fields.length ? new Set(fields) : null })}
						buildElement={(field) => field}
					/>
				</div>
				<div className="flex-row">
					<div className="comments-seeker">
						<Input
							placeholder="Szukaj w komentarzach"
							value={filters.commentsSearch}
							onChange={(e) => onFiltersChange({ commentsSearch: e.target.value })}
						/>
					</div>
					<Checkbox
						className="without-report-checkbox"
						checked={filters.withoutReport ?? false}
						onChange={(e) =>
							onFiltersChange({
								withoutReport: e.target.checked,
							})
						}
					>
						Pokaż tylko prace bez raportu
					</Checkbox>
				</div>
			</div>
			<Button type="primary" onClick={clearFilters}>
				Wyczyść filtry
			</Button>
		</Wrapper>
	);
};
