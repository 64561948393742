import React from 'react';
import { Print } from 'pages/prints/eko_works/print';
import { useEkoWorksPrint } from './use_eko_works_print';

interface Props {}

export const EkoWorksPrintPage: React.FC<Props> = () => {
	const { activities, ekoWorks, filters, processes, reports, users, params } = useEkoWorksPrint();

	if (!params) {
		return <h1>Invalid params</h1>;
	}

	return (
		<Print
			selectedColumns={params.selectedColumns}
			printLogo={params.printLogo}
			printHeader={params.printHeader}
			printPhotos={params.printPhotos}
			orientation={params.orientation}
			activities={activities}
			ekoWorks={ekoWorks}
			filters={filters}
			processes={processes}
			reports={reports}
			users={users}
		/>
	);
};
