export const formatNumber = (num: string) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatMoney = (num: number) => {
	return formatNumber(num.toFixed(2));
};

export const formatPercent = (num: number) => {
	const percent = num * 100;

	return formatNumber(percent.toFixed(2));
};

export const formatDate = (date: moment.Moment) => date.format('DD.MM.YYYY');
export const formatTime = (date: moment.Moment) => date.format('HH:mm');
export const formatDateTime = (date: moment.Moment) => date.format('DD.MM.YYYY HH:mm');

const Formatting = {
	formatMoney(num) {
		return Formatting.insertSpaces(parseFloat(num).toFixed(2));
	},

	insertSpaces(num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	},

	formatPercent(num: number) {
		const percent = num * 100;

		return Formatting.insertSpaces(percent.toFixed(2)).replace('.', ',');
	},
};

export default Formatting;
