import React from 'react';
import { styled } from 'styled-components';
import { Activity, EkoWork } from 'types';
import { formatNumber } from 'utils';
import groupEkoWorksByActivity, { GroupedWorks } from 'utils/groupEkoWorksByActivity';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	> table {
		width: auto;
	}
`;

interface Data {
	activity: Activity;
	amount: number;
}

interface Props {
	ekoWorks: EkoWork[] | undefined;
	activities: Activity[] | undefined;
}

export const Summary: React.FC<Props> = ({ ekoWorks, activities }) => {
	if (!ekoWorks || !activities) {
		return <div></div>;
	}

	const groupedWorks = Object.values(groupEkoWorksByActivity(ekoWorks, activities)) as GroupedWorks[];
	const data = groupedWorks.map<Data>((group) => {
		const sum = group.works.reduce((acc, work) => acc + work.amount, 0);

		return {
			activity: group.activity,
			amount: sum,
		};
	});

	return (
		<Wrapper>
			<h2>Podsumowanie</h2>
			<table>
				<thead>
					<tr>
						<th>Czynność</th>
						<th>Ilość</th>
						<th>Jednostka</th>
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<tr key={row.activity.id} className={index % 2 === 0 ? 'even' : 'odd'}>
							<td>{row.activity.name}</td>
							<td>{formatNumber(row.amount.toFixed(2))}</td>
							<td>{row.activity.unit}</td>
						</tr>
					))}
				</tbody>
			</table>
		</Wrapper>
	);
};
